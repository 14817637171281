import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {version} from '../../package.json';
import {MatSidenav} from '@angular/material/sidenav';
import {UserService} from './services/user.service';
import {shareReplay} from 'rxjs/operators';
import {AngularFireAuth} from '@angular/fire/auth';
import {SocketConnectionService} from './services/socket-connection.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  /**
   * Logged In Flag
   */
  public loggedIn = false;

  /**
   * The user if any
   */
  public user: any;

  /**
   * The active route
   */
  public activeRoute: string;

  /**
   * The application version
   */
  public version: string = version;

  /**
   * Subscription that listens to router change events
   */
  private routerSubscription: Subscription;

  /**
   * Authentication Subscription
   */
  private authenticationSubscription: Subscription;

  /**
   * The sidenav component
   */
  @ViewChild('sidenav') sidenav: MatSidenav;

  /**
   * The items to display in the navigation side menu
   */
  public navigation = [];

  public connected;

  /**
   * Constructor
   */
  constructor(private router: Router, private userService: UserService, private socketConnectionService: SocketConnectionService, private fireAuth: AngularFireAuth) {
    this.socketConnectionService.onChange.pipe(shareReplay(1)).subscribe((value) => {
      this.connected = value;
    });
  }

  /**
   * Register router change listener
   */
  ngOnInit(): void {
    this.routerSubscription = this.router.events.subscribe((event) => {
      this.sidenav.close();
      if (event instanceof NavigationEnd) {
        this.activeRoute = event.url;
      }
    });

    this.authenticationSubscription = this.userService.user.pipe(shareReplay(1)).subscribe((auth) => {
      if (auth) {
        this.loggedIn = true;
        this.user = auth;

        if (this.user.role !== 'admin') {
          this.navigation = [
              {label: 'Dashboard', route: ['/', 'dashboard'], activatedRoute: '/dashboard'},
              {label: 'Clients', route: ['/', 'clients'], activatedRoute: '/clients'},
              {label: 'Programs', route: ['/', 'programs'], activatedRoute: '/programs'},
          ];
        } else {
          this.navigation = [
            {label: 'Dashboard', route: ['/', 'dashboard'], activatedRoute: '/dashboard'},
            {label: 'Clients', route: ['/', 'clients'], activatedRoute: '/clients'},
            {label: 'Programs', route: ['/', 'programs'], activatedRoute: '/programs'},
            {label: 'Templates', route: ['/', 'templates'], activatedRoute: '/templates'},
            {label: 'Styles', route: ['/', 'styles'], activatedRoute: '/styles'},
            {label: 'Substyles', route: ['/', 'substyles'], activatedRoute: '/substyles'},
            {label: 'Superset Techniques', route: ['/', 'exercises', 'superset-technique'], activatedRoute: '/exercises/superset-technique'},
            {label: 'Techniques', route: ['/', 'exercises', 'technique'], activatedRoute: '/exercises/technique'},
            {label: 'Conditionings', route: ['/', 'exercises', 'conditioning'], activatedRoute: '/exercises/conditioning'},
            {label: 'Exercises', route: ['/', 'exercises', 'exercise'], activatedRoute: '/exercises/exercise'},
            {label: 'Body Parts', route: ['/', 'body-parts'], activatedRoute: '/body-parts'},
            {label: 'Nutrition Labels', route: ['/', 'nutrition-labels'], activatedRoute: ['/nutrition-labels']},
            {label: 'Administrators', route: ['/', 'administrators'], activatedRoute: '/administrators'},
            {label: 'Notifications', route: ['/', 'notifications'], activatedRoute: '/notifications'},
            {label: 'System Templates', route: ['/', 'system-templates'], activatedRoute: '/system-templates'},
            {label: 'System', route: ['/', 'system'], activatedRoute: '/system'},
          ];
        }
      } else {
        this.loggedIn = false;
        this.user = false;
      }
    });
  }

  /**
   * Clean up listeners
   */
  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
    this.authenticationSubscription.unsubscribe();
  }

  /**
   * Used for performant tracking
   */
  trackByLabel(index: number, item: any) {
    return item.label;
  }

  /**
   * Logs out
   */
  logout(): void {
    this.fireAuth.signOut().then(() => {
      this.userService.logout();
      setTimeout(() => { // This timeout give the observers time to react and clean up
        this.router.navigateByUrl('/');
      }, 10);
    });
  }
}
