import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = window.console.warn = window.console.info = () => {};
  }
}

/*import {ApplicationRef} from '@angular/core';
import {enableDebugTools} from '@angular/platform-browser';


platformBrowserDynamic().bootstrapModule(AppModule)
    .then(moduleRef => {
      const applicationRef = moduleRef.injector.get(ApplicationRef);
      const appComponent = applicationRef.components[0];
      enableDebugTools(appComponent);
    })*/

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
