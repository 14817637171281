import { Injectable } from '@angular/core';
import {Socket} from 'ngx-socket-io';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocketConnectionService {

  /**
   * Connected flag
   */
  private _connected = false;

  /**
   * On change subject/observer
   */
  public onChange = new Subject<boolean>();

  /**
   * Constructor
   * The purpose of this class is to have one central reference for the socket connection status
   */
  constructor(private socket: Socket) {
    this.onChange = new Subject<boolean>();
    this.socket.fromEvent('connect').pipe(
      // shareReplay(1)
    ).subscribe(() => {
      this._connected = true;
      this.onChange.next(this._connected);
    });

    this.socket.fromEvent('disconnect').pipe(
      // shareReplay(1)
    ).subscribe(() => {
      this._connected = false;
      this.onChange.next(this._connected);
    });
  }

  /**
   * Gets the connected value
   */
  get connected(): boolean {
    return this._connected;
  }
}
