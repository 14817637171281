import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from './avatar.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';



@NgModule({
  declarations: [AvatarComponent],
    imports: [
        CommonModule,
        LazyLoadImageModule
    ],
  exports: [AvatarComponent]
})
export class AvatarModule { }
