import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthenticatedGuard} from './guards/authenticated.guard';


const routes: Routes = [
  { path: '', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)},
  { path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), canLoad: [AuthenticatedGuard]},
  { path: 'administrators', loadChildren: () => import('./pages/administrators/administrators.module').then(m => m.AdministratorsModule), canLoad: [AuthenticatedGuard] },
  { path: 'body-parts', loadChildren: () => import('./pages/body-parts/body-parts.module').then(m => m.BodyPartsModule), canLoad: [AuthenticatedGuard] },
  { path: 'programs', loadChildren: () => import('./pages/programs/programs.module').then(m => m.ProgramsModule), canLoad: [AuthenticatedGuard] },
  { path: 'clients', loadChildren: () => import('./pages/clients/clients.module').then(m => m.ClientsModule), canLoad: [AuthenticatedGuard] },
  { path: 'exercises', loadChildren: () => import('./pages/exercises/exercises.module').then(m => m.ExercisesModule), canLoad: [AuthenticatedGuard] },
  { path: 'styles', loadChildren: () => import('./pages/styles/styles.module').then(m => m.StylesModule), canLoad: [AuthenticatedGuard] },
  { path: 'substyles', loadChildren: () => import('./pages/substyles/substyles.module').then(m => m.SubstylesModule), canLoad: [AuthenticatedGuard] },
  { path: 'templates', loadChildren: () => import('./pages/templates/templates.module').then(m => m.TemplatesModule), canLoad: [AuthenticatedGuard] },
  { path: 'nutrition-labels', loadChildren: () => import('./pages/nutrition-labels/nutrition-labels.module').then(m => m.NutritionLabelsModule), canLoad: [AuthenticatedGuard] },
  { path: 'notifications', loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsModule), canLoad: [AuthenticatedGuard] },
  { path: 'system-templates', loadChildren: () => import('./pages/system-templates/system-templates.module').then(m => m.SystemTemplatesModule), canLoad: [AuthenticatedGuard]},
  { path: 'system', loadChildren: () => import('./pages/system/system.module').then(m => m.SystemModule), canLoad: [AuthenticatedGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
