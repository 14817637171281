export const environment = {
  production: true,
  version: 1.5,
  socket: {
    url: 'https://emperor.aries4suf.com:4444',
    options: {}
  },
  firebase: {
    // cacheSizeBytes: firebase.firebase.CACHE_SIZE_UNLIMITED,
    apiKey: 'AIzaSyBGdQ16C0Y26pOupOWRt95tsD528i4jgkI',
    authDomain: 'sufapp-979e7.firebaseapp.com',
    databaseURL: 'https://sufapp-979e7.firebaseio.com',
    projectId: 'sufapp-979e7',
    storageBucket: 'sufapp-979e7.appspot.com',
    messagingSenderId: '827885755987',
    appId: '1:827885755987:web:1b1e195373051d9654b0a6'
  },
  debugging: {
    firebaseLog: true,
  },
  pdfSync: {
    api: 'https://utility.aries4suf.com/api/clients/pdf'
  },
  clientApp: {
    domain: 'https://www.aries4suf.com'
  }
};
