<div class="inner-wrap">
    <div class="fa fa-gauge-circle-bolt"  [routerLink]="['/']"></div>
    <div class="nav-icon"(click)="toggleSideNav()" [ngClass]="{active: isOpen}" *ngIf="isLoggedIn">
        <div></div>
    </div>
    <div class="logo" (click)="newWindow()">
        <img src="/assets/img/logo.svg"/>
    </div>
   <!-- <button type="button" (click)="connect()">Connect</button>
    <button type="button" (click)="disconnect()">Disconnect</button>-->
</div>

