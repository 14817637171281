import {environment as ProdEnvironemnt} from './environment.prod';

export const environment = {
  ...ProdEnvironemnt,
  socket: {
    url: 'https://emperor-beta.aries4suf.com:4444',
  },
  debugging: {
    firebaseLog: true,
  },
  pdfSync: {
    api: 'https://utility-beta.aries4suf.com/api/clients/pdf'
  },
  clientApp: {
    domain: 'https://beta.aries4suf.com'
  }
};
// samantha dipolo chart 14, May 3.
