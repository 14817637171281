import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Sex} from '../../models/sex/sex.type';
import {UploaderComponent} from '../uploader/uploader.component';
import {UploadedFileModel} from '../../models/photo/uploadedFileModel';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnChanges {

  @Input() sex: Sex;
  @Input() image: UploadedFileModel | string;
  @Input() clickable = false;
  @Input() uploaderComponent: UploaderComponent;
  public imageSource: string;


  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.image && changes.image.currentValue) {
      if (typeof changes.image.currentValue === 'string') {
        this.imageSource = changes.image.currentValue;
      } else if (changes.image.currentValue.url) {
        this.imageSource = changes.image.currentValue.url;
      } else {
        this.imageSource = null;
      }
    }
  }
}
