<mat-sidenav-container class="sidenav-container" [hasBackdrop]="false">
    <mat-sidenav #sidenav mode="side">
        <div class="inner" *ngIf="loggedIn">
            <div class="user" [routerLink]="['/', 'administrators', user.id]" >
                <div class="row v-center">
                    <div class="col-free">
                        <app-avatar class="avatar-small clickable" [image]="user.photo" [sex]="user.sex"></app-avatar>
                    </div>
                    <div class="col-free name">{{user.name}}</div>
                </div>
            </div>
            <ul>
                <li *ngFor="let nav of navigation; trackBy: trackByLabel" [ngClass]="{active: nav.activatedRoute === this.activeRoute}">
                    <a [routerLink]="nav.route"><span class="label">{{nav.label}}</span><i class="fa fa-chevron-right" aria-hidden="true"></i></a>
                </li>
                <li><a (click)="logout()"><span class="label">Logout</span><i class="fa fa-chevron-right" aria-hidden="true"></i></a></li>
            </ul>
            <p class="version">Version {{version}}</p>
        </div>
    </mat-sidenav>
    <mat-sidenav-content class="sidenav-content">
        <app-header [sidenav]="sidenav" [isLoggedIn]="loggedIn"></app-header>
        <div class="router-outlet">
            <!--<h1>CONNECTED: {{connected}}</h1><h1>Loggedin {{loggedIn}}</h1>-->
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
