import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import {MatSidenavModule} from '@angular/material/sidenav';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {AuthenticatedGuard} from './guards/authenticated.guard';
import {HeaderComponent} from './header/header.component';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {SocketIoModule} from 'ngx-socket-io';
import {HttpClientModule} from '@angular/common/http';
import {AvatarModule} from './modules/avatar/avatar.module';
import {MAT_DATE_LOCALE} from '@angular/material/core';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
  ],
  imports: [
      BrowserModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      SocketIoModule.forRoot(environment.socket),
      AngularFireModule.initializeApp(environment.firebase),
      AngularFireStorageModule,
      AngularFireAuthModule,
      MatSidenavModule,
      HttpClientModule,
      AvatarModule
  ],
  providers: [AuthenticatedGuard, {provide: MAT_DATE_LOCALE, useValue: 'en-US'}],
  bootstrap: [AppComponent]
})
export class AppModule { }
