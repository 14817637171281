import { Injectable } from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {Socket} from 'ngx-socket-io';
import {shareReplay, switchMap, tap} from 'rxjs/operators';
import {AngularFireAuth} from '@angular/fire/auth';
import {SocketConnectionService} from './socket-connection.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  /**
   * User Observable
   */
  public user: Observable<any>;

  /**
   * Constructor
   */
 /* constructor() {
    this.user = new BehaviorSubject<any>(true);
    this.user.next(true);
  }*/

  /**
   * Constructor
   */
  constructor(
    public fireAuth: AngularFireAuth,
    private socket: Socket,
    private socketConnectionService: SocketConnectionService) {
    this.user = this.fireAuth.authState.pipe(
      tap(value => console.log(`Read fireAuth.authState User Service`, value)),
      switchMap((value, index) => {
         // console.log('FirAuth User Changed, FireAuth value', value);
          if (value) {
            const subject = new Subject();
            // console.log('About to emit authentication');
            this.socket.emit('authentication', value.uid, () => {
              // console.log('Authentication response, about to emit getAuthenticatedUser');
              this.socket.emit(`getAuthenticatedUser`, value.uid, (response) => {
                // console.log('getAuthenticatedUser response', response);
                subject.next(response);

                let eventName = 'administrators.updated';

                if (! response || ! response.role) {
                  console.error(response);
                  throw new Error(response);
                }

                if (response.role === 'client') {
                  eventName = 'clients.updated';
                }

                // console.log('Registering user change event listener', eventName);
                this.socket.on(eventName, (clientRecord) => {
                  if (clientRecord.new_val.id === response.id) {
                    clientRecord.new_val.role = response.role;
                    subject.next(clientRecord.new_val);
                  }
                });
              });
            });

            return subject;
          } else {
            // console.log('FireAuth, we should send logout event to server');
            this.socket.emit('logout', null, () => {
              // console.log('FireAuth, server acknowledged logout');
            });
          }

          return of(false);
        }
      ),
      shareReplay(1));

    this.socketConnectionService.onChange.subscribe((change) => {
      if (change) {
        this.user.subscribe((user) => {
          if (user) {
            // console.log('Reconnected and we have a user', user);
            this.socket.emit('authentication', user.uid, () => {});
          } else {
            // console.log('Connected but no user', user);
          }
        }).unsubscribe();
      }
    });
  }

  logout(): Promise<any> {
    return this.socket.emit('logout');
  }
}
