import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {Subscription} from 'rxjs';
import {Socket} from 'ngx-socket-io';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Input() sidenav: MatSidenav;
  @Input() isLoggedIn: boolean;

  public isOpen = false;

  public menuOpenSubscription: Subscription;
  public menuCloseSubscription: Subscription;
  constructor(public socket: Socket) { }

  ngOnInit() {
    this.menuOpenSubscription = this.sidenav.openedStart.subscribe(() => {
      this.isOpen = true;
    });
    this.menuCloseSubscription = this.sidenav.closedStart.subscribe(() => {
      this.isOpen = false;
    });
  }

  ngOnDestroy(): void {
    this.menuOpenSubscription.unsubscribe();
    this.menuCloseSubscription.unsubscribe();
  }

  toggleSideNav() {
    this.isOpen = !this.isOpen;
    this.sidenav.toggle();
  }

  newWindow() {
    window.open(window.location.href);
  }

  connect() {

    this.socket.connect();

  }

  disconnect() {
    this.socket.disconnect();
  }
}
